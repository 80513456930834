// eslint-disable-next-line no-redeclare,no-unused-vars
function sendNotification(param = {}) {
  if (!param.ignoreMute && localStorage.getItem('mute') && !param.line) return;

  if (!Notification) return;
  if (Notification.permission != 'granted') {
    Notification.requestPermission().then(function (permission) {
      setData('user', user.id, 'notificationPermission', permission);
    });
    return;
  }
  var label = param.title;
  const {title} = window;
  if (!label) label = title;
  var notify = new Notification(title, {
    icon: 'img/favicon.svg',
    body: param.message,
  });
  notify.onclick = function () {
    notify.close();
    if (param.callback) param.callback();
  };
}
